import React from "react"
import { Link } from "gatsby"
import murrinaCardStyles from "../../styles/cards/murrina-card.module.scss"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import {isDateEarlier, formatDate} from "../../utilities/dateUtils"

const MurrinaCard = ({ murrina }) => {
  const settings = {
    arrows: false,
    dots: false,
    fade: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    swipeToSlide: true,
    pauseOnHover: true,
    fade: true
  }
  const today = new Date().toLocaleDateString('it-IT', { year: 'numeric', month: '2-digit', day: '2-digit' })
  const isTrattamentoValid = murrina.node.nome && murrina.node.slug && murrina.node.prezzo && murrina.node.categoria && murrina.node.marchio
  if (isTrattamentoValid) {
  return (
    <li key={murrina.node.strapiId} className={murrinaCardStyles.card}>
      <Link to={`${murrina.node.slug}`} >  
         {murrina.node.promo && murrina.node.promo.codice !== 'NOPROMO' && 
         isDateEarlier(formatDate(murrina.node.promo.inizio), today) && 
         isDateEarlier(today, formatDate(murrina.node.promo.fine)) && <span className={murrinaCardStyles.promo}>{murrina.node.promo.codice}</span>} 
        <Slider {...settings} >
        {murrina.node.variante_colore.map((variante,i) => (                    
          <div key={`${murrina.node.strapiId}-${i}`}>
            {(variante.foto[0].height >= variante.foto[0].width) || ((variante.foto[0].height < variante.foto[0].width) && (variante.foto[0].width / variante.foto[0].height < 1.5 ))?
            <img className={murrinaCardStyles.image} src={`${process.env.GATSBY_CLOUDINARY_URL_SMALL}${variante.foto[0].hash}`} loading="lazy"/> :  <img className={murrinaCardStyles.image} src={`${process.env.GATSBY_CLOUDINARY_URL_SMALL_ROTATE}${variante.foto[0].hash}`} loading="lazy"/>}
          </div>)
        )
     }
        </Slider>
        {murrina.node.nome.includes("stilografica") ? <span style={{fontSize: "0.6rem", color: "white", fontWeight: "bold", marginLeft: "50%", display:"inline-block", transform:"translateX(-50%)", marginTop: "5px"}}>KALIGRAFICA</span> : <img className={murrinaCardStyles.logo} src={murrina.node.marchio.logo[0].url}/>}
        <p className={murrinaCardStyles.nome}>{murrina.node.nome}</p>
      </Link>
    </li>
  )} return null
}


export default MurrinaCard