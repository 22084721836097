import React from "react"
import Layout from "../components/common/layout"
import { graphql } from "gatsby"
import murrineStyles from "../styles/pages/murrine.module.scss"
import MurrinaCard from "../components/cards/murrinaCard"

const Orologi = ({ data, location }) => {
  const murrine = data.allStrapiMurrine.edges || []

  return (
    <Layout
      pageMeta={{
        title: `Orologi`,
        keywords: [`orologi in legno`, `Laimer`, `Dolfi`],
        description: `Orologi in legno di Laimer e Dolfi`,
        link: `/orologi`,
      }}
      location={location}
    >
      <ul className={murrineStyles.murrineAltro}>
        {murrine.length ? (
          murrine.map(murrina => (
              <MurrinaCard  key={murrina.node.strapiId} murrina={murrina} />
          ))
        ) : (
          <p className={murrineStyles.text}>
            non ci sono prodotti in questa categoria
          </p>
        )}
      </ul>
    </Layout>
  )
}
export default Orologi

export const query = graphql`
  query OrologiQuery {
    allStrapiMurrine(filter: {categoria: {eq: "orologi"}}) {
      edges {
        node {
          strapiId
          slug
          nome
          categoria
          prezzo
          promo {
            codice
            inizio
            fine
          }
          marchio {
            nome
            logo {
              alternativeText
              hash
              width
              height
              url
            }
          }
          variante_colore {
            colore
            quantita
            foto {
              alternativeText
              hash
              width
              height
              url
            }
          }
        }
      }
    }
  }
`